import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
  Step,
  Icon,
  Message,
  Button
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toastr } from 'react-redux-toastr';
import _ from 'lodash';

import Workspace from './screens/Workspace';

import { app, db, googleProvider } from "./libs/firebase";
import {
  userFetchInformation,
  userIdentify,
  userUpdateUnit,
  userStaff,
  userFeatures,
  userCode,
} from "./redux/actions/users";
import { toString } from './libs/time';

const backend = (process.env.NODE_ENV === 'development') ? 'https://localhost:5003' : 'https://services.xbot.com.vn:1237';

class App extends Component {
  static propTypes = {
    userFetchInformation: PropTypes.func.isRequired,
    userIdentify: PropTypes.func.isRequired,
    userUpdateUnit: PropTypes.func.isRequired,
    userFeatures: PropTypes.func.isRequired,
    userStaff: PropTypes.func.isRequired,
    userCode: PropTypes.func.isRequired,
    user: PropTypes.shape({
      email: PropTypes.string.isRequired,
      uid: PropTypes.string.isRequired,
      phoneNumber: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
      unit: PropTypes.object
    }).isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      user: false,
      identify: false,
      error: ""
    };
  }

  componentWillMount() {
    this.removeAuthListener = app.auth().onAuthStateChanged((user) => {
      if (user) {
        const {
          displayName,
          email,
          phoneNumber,
          uid
        } = user;
        this.props.userFetchInformation(
          displayName,
          email,
          phoneNumber,
          uid
        );
        this.setState({ user: true });
        db.collection("users").doc(uid).get().then(snapshot => {
          db.collection("staffs").doc(email).get().then(isStaff => {
            const { userStaff: staffHandler } = this.props;
            this.readUserInfoSuccess(snapshot, email, isStaff.exists);
            if (isStaff.exists)
              staffHandler(_.get(isStaff.data(), 'admin', false));
          }).catch(() => {
            this.readUserInfoSuccess(snapshot, email);
          });
        }).catch(this.readInfoFailed);
      } else
        this.setState({ user: true, identify: true });
    });
  }

  componentWillUnmount() {
    this.removeAuthListener();
  }

  authWithGoogle = () => {
    app.auth().signInWithPopup(googleProvider).then((user, error) => {
      if (error) {
        toastr.error('Đăng nhập không thành công', 'Xin vui lòng thử lại sau.');
      }
    });
  }

  readUserInfoSuccess = (snapshot, email, staff) => {
    const {
      userIdentify: identifyHandler,
      userFeatures: featuresHandler,
      userUpdateUnit: unitHandler,
      userCode: codeHandler,
      user
    } = this.props;
    db.collection("certificates").doc(email).get().then(cert => {
      if (cert.exists) {
        const { features, unit } = cert.data();
        featuresHandler(features);
        db.collection("units").doc(unit).get().then((unit) => {
          if (unit.exists)
            unitHandler({ ...unit.data(), unitID: unit });
          this.setState({ identify: true });

          const license = unit.data();
          if (license.licenseExpired !== undefined) {
            license.licenseExpired = toString(license.licenseExpired);
          }
          axios.post(`${backend}/api/encode`, {
            data: {
              features,
              unit: license,
              email,
              staff,
            },
          }, { withCredentials: true, responseType: 'text' }).then((result) => {
            if (result.data) {
              codeHandler(result.data);
            } else toastr.warning('Lỗi kết nối', 'Không kiểm tra được thông tin bản quyền.');
          }).catch((err) => {
            toastr.warning('Lỗi kết nối', err);
          });
        }).catch(() => this.setState({ error: "Kết nối đến hệ thống không thành công. Xin vui lòng thử lại sau." }));
      } else
        this.setState({ identify: true });
    }).catch(() => this.setState({ error: "Kết nối đến hệ thống không thành công. Xin vui lòng thử lại sau." }));
    if (snapshot.exists) {
      const { displayName, phoneNumber, gender } = snapshot.data();
      identifyHandler(displayName, phoneNumber, gender);
    } else {
      const { user: defaultValues } = this.props;
      const { displayName: defaultDisplayName, phoneNumber: defaultPhone } = defaultValues;
      db.collection("users").doc(user.uid).set({
        email,
        displayName: defaultDisplayName,
        phoneNumber: defaultPhone
      });
    }
  }

  readInfoFailed = (error) => {
    if (error)
      this.setState({
        error: `Đọc thông tin người dùng thất bại. Lỗi: ${error}`
      });
  }

  renderProgress = () => {
    const { user, identify } = this.state;
    return (
      <div className="progress-style">
        <Step.Group>
          <Step active={!user} completed={user}>
            <Icon loading={!user} name="server" />
            <Step.Content>
              <Step.Title>Kiểm tra kết nối</Step.Title>
              <Step.Description>Hệ thống đang kết nối đến cơ sở dữ liệu người dùng</Step.Description>
            </Step.Content>
          </Step>
          <Step active={!identify} completed={identify}>
            <Icon loading={!identify} name="user" />
            <Step.Content>
              <Step.Title>Xác thực người dùng</Step.Title>
              <Step.Description>Hệ thống đang kiểm tra thông tin người dùng</Step.Description>
            </Step.Content>
          </Step>
        </Step.Group>
      </div>
    );
  }

  render() {
    const { error, user, identify } = this.state;
    if (error !== "")
      return (
        <div className="error-centered">
          <Message
            error
            header={error}
            list={[
              "Truy cập lại hệ thống vào thời điểm khác.",
              "Liên hệ đến công ty để được hỗ trợ.",
            ]}
          />
        </div>
      );
    if (!user || !identify)
      return this.renderProgress();

    const { user: userProp } = this.props;
    if (userProp.email)
      return <Workspace />;
    return (
      <div className='centered'>
        <Button.Group vertical labeled icon>
          <Button color='google plus' onClick={() => this.authWithGoogle()}>
            <Icon name='google' />
            Dùng Gmail
          </Button>
        </Button.Group>
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => ({
  user
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  userFetchInformation,
  userIdentify,
  userUpdateUnit,
  userStaff,
  userFeatures,
  userCode,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(App);