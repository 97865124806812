import { combineReducers } from 'redux';
import { reducer as notifications } from 'react-redux-toastr';

import user from './user';

const rootReducer = combineReducers({
  user,
  toastr: notifications,
});

export default rootReducer;