import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Divider,
  Button,
  Dimmer,
  Loader,
  Checkbox,
  Modal,
  Icon,
  Label,
  Header,
  Table,
  Segment,
  Feed
} from 'semantic-ui-react';
import { toastr } from 'react-redux-toastr';
import _ from 'lodash';

import TextInput from '../../components/TextInput';

import { db } from '../../libs/firebase';
import { convertTimeStamp } from '../../libs/time';


export default class Certificates extends Component {
  static propTypes = {
    closeHandler: PropTypes.func.isRequired,
    unitName: PropTypes.string.isRequired,
    unitID: PropTypes.string.isRequired,
    staff: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      isWaiting: true,
      accounts: {}
    };
  }

  componentWillMount() {
    const { unitID } = this.props;
    this.listener = db.collection('certificates').where('unit', '==', unitID).onSnapshot(result => {
      const accounts = [];
      result.forEach(account => {
        accounts.push({
          email: account.id,
          features: _.split(_.get(account.data(), 'features', ''), ','),
          lasted: _.get(account.data(), 'lasted', {})
        });
      });
      this.setState({
        isWaiting: false,
        accounts
      });
    }, error => {
      this.setState({
        isWaiting: false
      });
      toastr.error('Lỗi', error);
    });
  }

  componentWillUnmount() {
    if (this.listener)
      this.listener();
  }

  validate = () => {
    const { account } = this.state;
    const { email } = account;
    if (!email) {
      toastr.warning('Thông tin không hợp lệ', 'Email không được bỏ trống.');
      return false;
    }
    if (!(/\S+@\S+\.\S+/.test(email))) {
      toastr.warning('Thông tin không hợp lệ', 'Email không đúng định dạng.');
      return false;
    }
    return true;
  }

  readModule = (mod) => {
    switch (mod) {
      case 'dinhduong':
        return 'Dinh dưỡng';
      case 'sosachbantru':
        return 'Sổ sách bán trú';
      default:
        return mod;
    }
  }

  removeHandler = () => {
    this.setState({ isWaiting: true });
    const { account } = this.state;
    const { email } = account;
    db.collection('certificates').doc(email).delete().then(() => {
      this.setState({ isWaiting: false });
      toastr.success('Hoàn tất', 'Xoá thành công.');
    }).catch(error => {
      this.setState({ isWaiting: false })
      toastr.error('Lỗi', error);
    })
  }

  saveHandler = () => {
    if (!this.validate())
      return;
    this.setState({ isWaiting: true });
    const { account } = this.state;
    const { oldEmail, email, features } = account;
    const { unitID, staff } = this.props;
    const updateContent = {
      unit: unitID,
      lasted: {
        staff,
        time: new Date().getTime()
      }
    };
    if (features.length)
      updateContent.features = _.join(features, ',');
    if (oldEmail && oldEmail !== email)
      db.collection('certificates').doc(oldEmail).delete();
    db.collection('certificates').doc(email).set(updateContent).then(() => {
      this.setState({ account: undefined });
      toastr.success('Hoàn tất', 'Cập nhật thành công.');
    }).catch(error => {
      this.setState({ isWaiting: false });
      toastr.error('Lỗi', error);
    });
  }

  renderAccount = () => {
    const { account, isWaiting } = this.state;
    if (!account)
      return null;
    const {
      oldEmail,
      email,
      features,
      lasted
    } = account;
    const dinhduong = _.indexOf(features, 'dinhduong') !== -1;
    const sosachbantru = _.indexOf(features, 'sosachbantru') !== -1;
    return (
      <Modal
        className="custom"
        size="small"
        open
        closeIcon={(
          <Icon
            name="close"
            color="red"
            onClick={() => this.setState({ account: undefined })}
          />
        )}
      >
        <Header as="h3">
          {oldEmail ? `Tài khoản ${oldEmail}` : 'Thêm mới'}
          {_.get(lasted, 'staff') ? (
            <Header.Subheader>
              <Feed style={{ marginBottom: 0 }}>
                <Feed.Event>
                  <Feed.Content>
                    <Feed.Summary>
                      <Feed.User>{_.get(lasted, 'staff')}</Feed.User>
                      {' cập nhật '}
                      <Feed.Date>{convertTimeStamp(_.get(lasted, 'time'))}</Feed.Date>
                    </Feed.Summary>
                  </Feed.Content>
                </Feed.Event>
              </Feed>
            </Header.Subheader>
          ) : null
          }
        </Header>
        <Modal.Content>
          {isWaiting ?
            <Dimmer active inverted>
              <Loader inverted>Đang kết nối...</Loader>
            </Dimmer>
            : null
          }
          <Form>
            <Form.Field>
              <label>Email:</label>
              <TextInput value={email} onChange={value => this.setState({ account: { ...account, email: value } })} />
            </Form.Field>
            <Form.Field>
              <Checkbox
                label="Dinh dưỡng"
                checked={dinhduong}
                onChange={() => {
                  if (dinhduong)
                    this.setState({
                      account: {
                        ...account,
                        features: _.filter(features, feature => feature !== 'dinhduong')
                      }
                    });
                  else
                    this.setState({
                      account: {
                        ...account,
                        features: _.concat(features, ['dinhduong'])
                      }
                    });
                }}
              />
              <Checkbox
                style={{ marginLeft: 10 }}
                label="Sổ sách bán trú"
                checked={sosachbantru}
                onChange={() => {
                  if (sosachbantru)
                    this.setState({
                      account: {
                        ...account,
                        features: _.filter(features, feature => feature !== 'sosachbantru')
                      }
                    });
                  else
                    this.setState({
                      account: {
                        ...account,
                        features: _.concat(features, ['sosachbantru'])
                      }
                    });
                }}
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          {oldEmail ? <Button negative onClick={this.removeHandler}>Xoá</Button> : null}
          <Button primary onClick={this.saveHandler}>Lưu</Button>
        </Modal.Actions>
      </Modal>
    )
  }

  render() {
    const { unitName, unitID, closeHandler } = this.props;
    const { isWaiting, accounts } = this.state;
    const headerStyles = {
      color: '#8798AD',
      textAlign: 'left'
    };
    return (
      <Modal
        className="custom"
        open
        closeIcon={(
          <Icon
            name="close"
            color="red"
            onClick={closeHandler}
          />
        )}
      >
        <Header
          as="h3"
          content={unitName}
          subheader={unitID}
        />
        <Modal.Content scrolling>
          {this.renderAccount()}
          {isWaiting ?
            <Dimmer active inverted>
              <Loader inverted>Đang kết nối...</Loader>
            </Dimmer>
            : null
          }
          <Segment>
            <Header as="h4">
              Số tài khoản: {_.keys(accounts).length}
            </Header>
            <Divider />
            <Table
              style={{ border: 'none' }}
              basic
              striped
              unstackable
            >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell style={headerStyles}>Email</Table.HeaderCell>
                  <Table.HeaderCell collapsing style={headerStyles}>Phân hệ</Table.HeaderCell>
                  <Table.HeaderCell collapsing style={headerStyles} />
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {_.map(
                  _.orderBy(
                    accounts,
                    account => account.email
                  ),
                  account => {
                    const { email, features, lasted } = account;
                    return (
                      <Table.Row key={email}>
                        <Table.Cell>{email}</Table.Cell>
                        <Table.Cell collapsing>
                          {_.map(features, feature => <Label key={feature}>{this.readModule(feature)}</Label>)}
                        </Table.Cell>
                        <Table.Cell collapsing>
                          <Icon
                            style={{ cursor: 'pointer' }}
                            name="edit"
                            color="blue"
                            onClick={() => this.setState({
                              account: {
                                oldEmail: email,
                                email,
                                features,
                                lasted
                              }
                            })}
                          />
                        </Table.Cell>
                      </Table.Row>
                    )
                  })}
              </Table.Body>
            </Table>
          </Segment>
        </Modal.Content>
        <Modal.Actions>
          <Button positive onClick={() => this.setState({ account: { email: '', features: [] } })}>Thêm mới</Button>
        </Modal.Actions>
      </Modal >
    );
  }
}