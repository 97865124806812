/**
 * Convert timestamp to short datetime
 *
 * @module Libs.time
 * @ngdoc method
 * @name $convertTimeStamp
 * @param  {Number} [timestamp] unix timestamp
 * @param {Bool} if full option is specified, return full datatime
 * @return {String} formatted datetime
 */
function convertTimeStamp(timestamp, full) {
  const date = new Date(timestamp);
  if (full !== undefined && full === true) {
    return `Ngày ${date.getDate()} Tháng ${(date.getMonth() + 1)}, Năm ${(date.getFullYear())}`;
  }
  const diff = (new Date()).getTime() - timestamp;

  const mins = diff / (1000 * 60);
  const hours = mins / 60;
  const days = hours / 24;

  if (parseInt(mins, 10) === 0)
    return 'vừa mới đây';
  else if (parseInt(hours, 10) === 0)
    return `${parseInt(mins, 10)} phút trước`;
  switch (parseInt(days, 10)) {
    case 0:
      return `${parseInt(hours, 10)} giờ trước`;
    case 1:
      return 'Hôm qua';
    default:
      return `Ngày ${date.getDate()} Tháng ${(date.getMonth() + 1)}`;
  }
}

/**
 * Check if time was passed
 *
 * @module Libs.time
 * @ngdoc method
 * @name $isTimePassed
 * @param  {Number} [timestamp] unix timestamp
 * @return {Bool} true if time was passed
 */
function isTimePassed(timestamp) {
  const today = new Date();
  const date = new Date(timestamp);

  if (date.getFullYear() > today.getFullYear())
    return false;
  if (date.getFullYear() < today.getFullYear())
    return true;

  if (date.getMonth() > today.getMonth())
    return false;
  if (date.getMonth() < today.getMonth())
    return true;

  if (date.getDate() > today.getDate())
    return false;
  if (date.getDate() < today.getDate())
    return true;

  return false;
}

function toString(timestamp) {
  const date = new Date(timestamp);
  return `${date.getDate()}-${(date.getMonth() + 1)}-${(date.getFullYear())}`;
}

export { convertTimeStamp, isTimePassed, toString }