import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';
import _ from 'lodash';


export default class TextInput extends Component {
  static propTypes = {
    value: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool
  };

  static defaultProps = {
    placeholder: '',
    disabled: false,
    onChange: undefined
  };

  constructor(props) {
    super(props);

    const { value } = this.props;
    this.state = {
      value
    };
  }

  onChange = _.debounce(() => {
    const { value } = this.state;
    const { onChange } = this.props;
    onChange(value);
  }, 200);

  render() {
    const { placeholder, disabled, onChange } = this.props;
    const { value } = this.state;
    return (
      <Input
        fluid
        disabled={disabled || !onChange}
        placeholder={placeholder}
        value={value}
        onChange={(event, { value: newValue }) => this.setState({ value: newValue }, () => {
          this.onChange();
        })}
      />
    );
  }
}
