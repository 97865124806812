import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Input,
  Divider,
  Button,
  Dimmer,
  Loader,
  Dropdown,
  Checkbox,
  Popup,
  Modal,
  Icon,
  Label,
  Header
} from 'semantic-ui-react';
import Calendar from 'react-calendar';
import { toastr } from 'react-redux-toastr';
import _ from 'lodash';

import Certificates from './certificates';
import { db } from '../../libs/firebase';
import { convertTimeStamp } from '../../libs/time';


const cities = require('../../libs/cities.json');
const uuid = require('uuid/v1');

const modules = [{
  label: 'Kho',
  value: 'quanlykho'
}, {
  label: 'Thực đơn',
  value: 'candoithucdon'
}, {
  label: 'Bán trú',
  value: 'quanlybantru'
}, {
  label: 'Tiền ăn',
  value: 'tinhtienan'
}, {
  label: 'Thu phí',
  value: 'quanlythuphi'
}, {
  label: 'Học sinh',
  value: 'quanlyhocsinh'
}, {
  label: 'Danh mục',
  value: 'danhmuc'
}, {
  label: 'Cài đặt',
  value: 'caidat'
}];

export default class Unit extends Component {
  static propTypes = {
    closeHandler: PropTypes.func.isRequired,
    unit: PropTypes.shape({
      name: PropTypes.string.isRequired,
      licenseExpired: PropTypes.number,
      note: PropTypes.string,
      unitID: PropTypes.string,                 // if create new unit, leave it undefined
      province: PropTypes.string,
      district: PropTypes.string,
      ward: PropTypes.string,
      quanlykho: PropTypes.bool,
      candoithucdon: PropTypes.bool,
      quanlybantru: PropTypes.bool,
      tinhtienan: PropTypes.bool,
      quanlythuphi: PropTypes.bool,
      quanlyhocsinh: PropTypes.bool,
      danhmuc: PropTypes.bool,
      caidat: PropTypes.bool
    }).isRequired,
    staff: PropTypes.string.isRequired,
    admin: PropTypes.bool
  }

  static propTypes = {
    admin: false
  };

  constructor(props) {
    super(props);

    this.state = {
      isWaiting: false,
      name: this.props.unit.name,
      note: (this.props.unit.note === undefined) ? '' : this.props.unit.note,
      districts: [],
      wards: [],
      selectedDistrict: undefined,
      selectedWard: undefined,
      licenseExpired: this.props.unit.licenseExpired,
      modules: {
        quanlykho: _.get(this.props.unit, 'quanlykho', false),
        candoithucdon: _.get(this.props.unit, 'candoithucdon', false),
        quanlybantru: _.get(this.props.unit, 'quanlybantru', false),
        tinhtienan: _.get(this.props.unit, 'tinhtienan', false),
        quanlythuphi: _.get(this.props.unit, 'quanlythuphi', false),
        quanlyhocsinh: _.get(this.props.unit, 'quanlyhocsinh', false),
        danhmuc: _.get(this.props.unit, 'danhmuc', false),
        caidat: _.get(this.props.unit, 'caidat', false)
      }
    }
  }

  componentWillMount() {
    if (cities[this.props.unit.province] !== undefined && cities[this.props.unit.province].districts !== undefined) {
      const districts = [];
      Object.keys(cities[this.props.unit.province].districts).map(key => (
        districts.push({
          key,
          text: cities[this.props.unit.province].districts[key].name,
          value: key
        })
      ));
      if (this.props.unit.district === undefined)
        this.setState({
          districts,
          wards: [],
          selectedDistrict: undefined,
          selectedWard: undefined
        });
      else {
        const wards = [];
        Object.keys(cities[this.props.unit.province].districts[this.props.unit.district].wards).map(key => (
          wards.push({
            key,
            text: cities[this.props.unit.province].districts[this.props.unit.district].wards[key].name,
            value: key
          })
        ));
        if (this.props.unit.ward !== undefined)
          this.setState({
            districts,
            wards,
            selectedDistrict: this.props.unit.district,
            selectedWard: this.props.unit.ward
          });
        else
          this.setState({
            districts,
            wards,
            selectedDistrict: this.props.unit.district,
            selectedWard: undefined
          });
      }
    }
  }

  validate = () => {
    const { name, selectedWard } = this.state;
    if (!name) {
      toastr.warning('Thông tin không hợp lệ', 'Tên đơn vị không được bỏ trống.');
      return false;
    }
    if (!selectedWard) {
      toastr.warning('Thông tin không hợp lệ', 'Phường/Xã không được bỏ trống.');
      return false;
    }
    return true;
  }

  // Update selected District and get new list of Wards which are belong to selected District
  districtHandler = (event, { value }) => {
    this.setState({
      selectedDistrict: value,
      wards: [],
      selectedWard: undefined
    });
    const wards = [];
    Object.keys(cities[this.props.unit.province].districts[value].wards).map(key => (
      wards.push({
        key,
        text: cities[this.props.unit.province].districts[value].wards[key].name,
        value: key
      })
    ));
    this.setState({ wards });
  }

  wardHandler = (event, { value }) => {
    this.setState({ selectedWard: value });
  }

  saveHandler = () => {
    if (!this.validate())
      return;
    this.setState({ isWaiting: true });
    const updateContent = {
      name: this.state.name,
      note: this.state.note,
      province: this.props.unit.province,
      district: this.state.selectedDistrict,
      ward: this.state.selectedWard,
      quanlykho: this.state.modules.quanlykho,
      candoithucdon: this.state.modules.candoithucdon,
      quanlybantru: this.state.modules.quanlybantru,
      tinhtienan: this.state.modules.tinhtienan,
      quanlythuphi: this.state.modules.quanlythuphi,
      quanlyhocsinh: this.state.modules.quanlyhocsinh,
      danhmuc: this.state.modules.danhmuc,
      caidat: this.state.modules.caidat,
    };
    console.log(this.state.licenseExpired)
    if (this.state.licenseExpired !== undefined)
      updateContent.licenseExpired = this.state.licenseExpired;

    const unitID = (this.props.unit.unitID !== undefined) ? this.props.unit.unitID : uuid();
    db.collection("units").doc(unitID).set(
      updateContent,
      { merge: true }
    ).then(() => {
      toastr.success('Hoàn tất', 'Cập nhật thành công');
      this.setState({ isWaiting: false });
    }).catch(() => toastr.error('Lỗi', 'Xin vui lòng thử lại sau.'));
  }

  removeHandler = () => {
    const { unit, closeHandler } = this.props;
    const { unitID } = unit;
    if (!unitID)
      return;
    this.setState({ isWaiting: true });
    const batch = db.batch();
    db.collection('certificates').where('unit', '==', unitID).get().then(users => {
      users.forEach(user => {
        batch.delete(db.collection('certificates').doc(user.id));
      });
      batch.delete(db.collection('units').doc(unitID));
      batch.commit().then(() => {
        toastr.success('Hoàn tất', 'Xoá đơn vị thành công.');
        closeHandler();
      }).catch(error => {
        this.setState({ isWaiting: false });
        toastr.error('Lỗi', error);
      });
    });
  }

  toggleCheckAll = () => {
    if (this.state.modules.quanlykho)
      this.setState({
        modules: {
          quanlykho: false,
          candoithucdon: false,
          quanlybantru: false,
          tinhtienan: false,
          quanlythuphi: false,
          quanlyhocsinh: false,
          danhmuc: false,
          caidat: false
        }
      });
    else
      this.setState({
        modules: {
          quanlykho: true,
          candoithucdon: true,
          quanlybantru: true,
          tinhtienan: true,
          quanlythuphi: true,
          quanlyhocsinh: true,
          danhmuc: true,
          caidat: true
        }
      });
  }

  toggleModule = (mod) => {
    this.setState({
      modules: {
        ...this.state.modules,
        [mod]: !this.state.modules[mod]
      }
    });
  }

  renderModules = () => (
    <Form.Field>
      <label>Tính năng</label>
      <Form.Field>
        <Checkbox
          label="Tất cả"
          value="all"
          checked={this.state.modules.quanlykho && this.state.modules.candoithucdon && this.state.modules.quanlybantru && this.state.modules.tinhtienan && this.state.modules.quanlythuphi && this.state.modules.quanlyhocsinh && this.state.modules.danhmuc && this.state.modules.caidat}
          onChange={this.toggleCheckAll}
        />
      </Form.Field>
      <Form.Group inline>
        {modules.map((type, index) => (
          <Form.Field key={index}>
            <Checkbox
              label={type.label}
              value={type.value}
              checked={this.state.modules[type.value]}
              onChange={() => this.toggleModule(type.value)}
            />
          </Form.Field>
        ))}
      </Form.Group>
    </Form.Field>
  )

  renderProvinceDistrictWard = () => (
    <Form.Group widths="equal">
      <Form.Field>
        <label>Tỉnh</label>
        <Input value={cities[this.props.unit.province].name} />
      </Form.Field>
      <Form.Field>
        <label>Quận/Huyện</label>
        <Dropdown label="Quận: " search selection multiple={false} onChange={this.districtHandler} options={this.state.districts} value={this.state.selectedDistrict} placeholder="Nhập tên Quận Huyện" />
      </Form.Field>
      <Form.Field required>
        <label>Phường/Xã</label>
        <Dropdown label="Phường: " search selection multiple={false} onChange={this.wardHandler} options={this.state.wards} value={this.state.selectedWard} placeholder="Nhập tên Phường Xã" />
      </Form.Field>
    </Form.Group>
  )

  render() {
    const {
      unit,
      closeHandler,
      staff,
      admin
    } = this.props;
    const {
      isWaiting,
      name,
      note,
      licenseExpired,
      certificate,
      removeConfirm
    } = this.state;
    return (
      <Modal
        className="custom"
        open
        closeIcon={(
          <Icon
            name="close"
            color="red"
            onClick={closeHandler}
          />
        )}
      >
        <Header
          as="h3"
          content={unit.unitID ? unit.name : `Thêm mới ${unit.name}`}
          subheader={unit.unitID ? <Label color="grey"><Icon name="key" /> {unit.unitID}</Label> : null}
        />
        <Modal.Content scrolling>
          {removeConfirm ? (
            <Modal
              className="custom"
              size="small"
              open
              closeIcon={(
                <Icon
                  name="close"
                  color="red"
                  onClick={() => this.setState({ removeConfirm: false })}
                />
              )}
            >
              <Modal.Header>Xoá đơn vị</Modal.Header>
              <Modal.Content>
                Thao tác này cũng sẽ xoá hết các tài khoản được cấp cho đơn vị này. Tiếp tục?
              </Modal.Content>
              <Modal.Actions>
                <Button negative onClick={this.removeHandler}>Tiếp tục</Button>
              </Modal.Actions>
            </Modal>
          ) : null
          }
          {isWaiting ?
            <Dimmer active inverted>
              <Loader inverted>Đang kết nối...</Loader>
            </Dimmer>
            : null
          }
          {certificate ? (
            <Certificates
              closeHandler={() => this.setState({ certificate: false })}
              unitName={unit.name}
              unitID={unit.unitID}
              staff={staff}
            />
          ) : null
          }
          <Form>
            {this.renderProvinceDistrictWard()}
            <Form.Field required>
              <label>Tên đơn vị</label>
              <Input placeholder="Tên đơn vị" value={name} onChange={(event, { value }) => this.setState({ name: value })} />
            </Form.Field>
            <Form.Field>
              <label>Ghi chú</label>
              <Form.TextArea placeholder="Ghi chú" value={note} onChange={(event, { value }) => this.setState({ note: value })} />
            </Form.Field>
            <Divider />
            {this.renderModules()}
            <Divider />
            <Form.Field>
              <label>Hạn sử dụng đến</label>
              <Popup
                trigger={<Button color="red" icon="clock" content={(licenseExpired !== undefined) ? convertTimeStamp(this.state.licenseExpired, true) : "Cập nhật hạn sử dụng"} />}
                on="click"
                hideOnScroll
              >
                <Calendar
                  minDate={new Date()}
                  minDetail="year"
                  value={licenseExpired ? new Date(licenseExpired) : undefined}
                  onChange={(date) => this.setState({ licenseExpired: date.getTime() })}
                  locale="vi-VI"
                />
              </Popup>
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          {unit.unitID ? <Button onClick={() => this.setState({ certificate: true })}>Tài khoản</Button> : null}
          {admin ? <Button negative onClick={() => this.setState({ removeConfirm: true })}>Xoá đơn vị</Button> : null}
          <Button primary onClick={this.saveHandler}>Cập nhật</Button>
        </Modal.Actions>
      </Modal >
    );
  }
}