import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import ReduxToastr from 'react-redux-toastr';
import { createStore, compose } from 'redux';
import { Provider } from 'react-redux';

import App from './App';

import registerServiceWorker from './registerServiceWorker';
import reducer from './redux/reducers';

import './semantic/semantic.min.css';
import 'react-infinite-calendar/styles.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import './App.css';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; //eslint-disable-line
const store = createStore(reducer, composeEnhancers());

ReactDOM.render((
  <Provider store={store}>
    <Fragment>
      <ReduxToastr
        timeOut={4000}
        closeOnToastrClick
      />
      <App />
    </Fragment>
  </Provider>
), document.getElementById('root'));

registerServiceWorker();
