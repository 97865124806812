import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';


const config = {
  apiKey: 'AIzaSyBrH88gb8c9wQYsGMwRAQM3QZe5jQd6z3k',
  authDomain: 'qlmn-app.firebaseapp.com',
  databaseURL: 'https://qlmn-app.firebaseio.com',
  projectId: 'qlmn-app',
  storageBucket: '',
  messagingSenderId: '493248189392'
};

const app = firebase.initializeApp(config);
app.auth().languageCode = 'vi';
const db = app.firestore();
const googleProvider = new firebase.auth.GoogleAuthProvider();

export { app, db, googleProvider }