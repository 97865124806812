import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Header,
  Label,
  Message,
  Segment,
  Icon
} from 'semantic-ui-react';
import { connect } from 'react-redux';

import packageJson from '../../../package.json';
import Units from './units';
import ProfileForm from '../../components/ProfileForm';
import { convertTimeStamp, isTimePassed } from '../../libs/time';


class Workspace extends Component {
  static propTypes = {
    user: PropTypes.shape({
      uid: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
      gender: PropTypes.string,
      staff: PropTypes.bool,
      unit: PropTypes.shape({
        licenseExpired: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        quanlykho: PropTypes.bool,
        candoithucdon: PropTypes.bool,
        quanlybantru: PropTypes.bool,
        tinhtienan: PropTypes.bool,
        quanlythuphi: PropTypes.bool,
        quanlyhocsinh: PropTypes.bool,
        danhmuc: PropTypes.bool,
        caidat: PropTypes.bool
      }),
      features: PropTypes.string.isRequired
    }).isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      open: true
    };
  }

  renderStatus = () => {
    const { user } = this.props;
    if (user.staff)
      return <Label color="blue" icon="certificate" content="Admin" />;
    if (!user.unit)
      return <Label color="red" icon="certificate" content="Xin liên hệ công ty để được cấp bản quyền." />;
    if (!user.unit.licenseExpired)
      return (
        <Segment>
          <Label color="blue" icon="certificate" content={user.unit.name} />
          <Label color="red" icon="clock" content="Không bị giới hạn thời gian sử dụng" />
        </Segment>
      );
    return (
      <Segment>
        <Label color="blue" icon="certificate" content={user.unit.name} />
        {(isTimePassed(new Date(user.unit.licenseExpired).getTime())) ?
          <Label color="red" icon="clock" content="Bản quyền sử dụng đã hết hạn. Xin liên hệ công ty để được gia hạn sử dụng." />
          :
          <Label color="green" icon="clock" content={`Hạn dùng đến: ${convertTimeStamp(user.unit.licenseExpired, true)}`} />
        }
      </Segment>
    );
  }

  renderUnit = () => {
    const { user } = this.props;
    if (user.unit === undefined)
      return null;
    const { unit, features: data } = user;
    let features = "Chức năng: ";
    if (data.indexOf("dinhduong") !== -1 && data.indexOf("sosachbantru") !== -1) {
      if (unit.quanlykho) features += "Quản lý kho; ";
      if (unit.candoithucdon) features += "Cân đối thực đơn; ";
      if (unit.quanlybantru) features += "Quản lý bán trú; ";
      if (unit.tinhtienan) features += "Tính tiền ăn; ";
      if (unit.quanlythuphi) features += "Quản lý thu phí; ";
      if (unit.quanlyhocsinh) features += "Quản lý học sinh; ";
      if (unit.danhmuc) features += "Danh mục; ";
      if (unit.caidat) features += "Cài đặt; ";
    } else if (data.indexOf("dinhduong") !== -1)
      features += "Dinh dưỡng";
    else if (data.indexOf("sosachbantru") !== -1)
      features += "Số sách bán trú";

    if (features === "Chức năng: ")
      features = "Chưa kích hoạt tính năng nào. Xin liên hệ công ty để được hỗ trợ";
    return (
      <Message positive style={{ borderRadius: 10, margin: 10 }}>
        <Message.Header>{unit.name}</Message.Header>
        <p>{features}</p>
      </Message>
    );
  }

  render() {
    const { user } = this.props;
    const { open } = this.state;
    return (
      <div style={{ margin: 20, paddingBottom: 50 }}>
        <Modal
          className="custom"
          open={open}
          closeIcon={(
            <Icon
              name="close"
              color="red"
              onClick={() => {
                if (user.staff) {
                  this.setState({ open: false });
                }
              }}
            />
          )}
        >
          <Header as="h3" icon="id card outline" content="Thông tin tài khoản" />
          <Header.Subheader>{this.renderStatus()}</Header.Subheader>
          {this.renderUnit()}
          <Modal.Content>
            <ProfileForm />
          </Modal.Content>
        </Modal>
        {user.staff ? <Units staff={user.email} /> : null}
        <div
          style={{
            position: 'fixed',
            right: 0,
            bottom: 0,
            left: 0,
            padding: '1rem',
            background: '#FFFFFF',
            height: 56
          }}
        >
          <span style={{ fontSize: 12, color: '#454545' }}>{`QLMN v${packageJson.version} - © 2018, XBOT Technology JSC. All Rights Reserved.`}</span>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps)(Workspace);