import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Form,
  Button,
  Input,
  Dimmer,
  Loader,
  Dropdown,
  Divider,
  TextArea,
} from "semantic-ui-react";
import { toastr } from 'react-redux-toastr';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from 'lodash';

import { app, db } from "../../libs/firebase";
import { userIdentify, signOut } from "../../redux/actions/users";

class ProfileForm extends Component {
  static propTypes = {
    signOut: PropTypes.func.isRequired,
    userIdentify: PropTypes.func.isRequired,
    user: PropTypes.shape({
      uid: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
      phoneNumber: PropTypes.string.isRequired,
      gender: PropTypes.string,
      unit: PropTypes.object,
      code: PropTypes.string,
    }).isRequired
  };

  constructor(props) {
    super(props);

    const { user } = this.props;
    this.state = {
      isWaiting: false,
      displayName: user.displayName,
      phoneNumber: user.phoneNumber,
      gender: user.gender,
    };
  }

  validateInformation = () => {
    const { displayName, gender } = this.state;
    if (!displayName) {
      toastr.warning('Thông tin không hợp lệ', 'Họ và tên không được bỏ trống.');
      return false;
    }
    if (!gender) {
      toastr.warning('Thông tin không hợp lệ', 'Giới tính không được bỏ trống.');
      return false;
    }
    return true;
  }

  saveHandler = () => {
    if (this.validateInformation()) {
      this.setState({
        isWaiting: true
      });
      const { user } = this.props;
      const { displayName, phoneNumber, gender } = this.state;
      db.collection("users").doc(user.uid).update({
        displayName,
        phoneNumber,
        gender
      }).then(this.updateComplete).catch(this.updateComplete);
    }
  }

  updateComplete = (error) => {
    if (error) {
      toastr.error('Cập nhật không thành công', 'Xin vui lòng thử lại sau.');
      this.setState({
        isWaiting: false
      });
      return;
    }
    const { displayName, phoneNumber, gender } = this.state;
    this.props.userIdentify(displayName, phoneNumber, gender);
    this.setState({ isWaiting: false });
    toastr.success('Hoàn tất', 'Cập nhật thành công');
  }

  logoutHandler = () => {
    const { signOut: handler } = this.props;
    app.auth().signOut().then(() => {
      handler();
    });
  }

  render() {
    const { user } = this.props;
    const {
      isWaiting,
      displayName,
      phoneNumber,
      gender,
    } = this.state;
    return (
      <div>
        {isWaiting ? (
          <Dimmer active inverted>
            <Loader inverted>Đang kết nối...</Loader>
          </Dimmer>
        )
          : null}
        <Form>
          <Form.Field required>
            <label>Họ và tên</label>
            <Input
              value={displayName}
              onChange={(e, { value }) => this.setState({ displayName: value })}
              icon="user"
              placeholder="Họ và tên"
              iconPosition="left"
            />
          </Form.Field>
          <Form.Field>
            <label>Số điện thoại</label>
            <Input
              value={phoneNumber}
              onChange={(e, { value }) => this.setState({ phoneNumber: value })}
              icon="phone"
              placeholder="Số điện thoại"
              iconPosition="left"
            />
          </Form.Field>
          <Form.Field required>
            <label>Giới tính</label>
            <Dropdown
              selection
              multiple={false}
              onChange={(event, { value }) => {
                this.setState({ gender: value });
              }}
              options={[{ key: "M", text: "Nam", value: "M" }, { key: "F", text: "Nữ", value: "F" }]}
              defaultValue={gender}
              placeholder="Giới tính"
            />
          </Form.Field>

          <Divider />

          <Form.Field>
            <label>Mã phần mềm Offline</label>
            <TextArea
              id="qlmn_code"
              value={_.get(user, 'code', '')}
              placeholder="Đang lấy mã..."
            />
          </Form.Field>
          <Button
            size="small"
            minimal
            negative
            onClick={() => {
              const codeContainer = document.getElementById('qlmn_code');
              if (codeContainer) {
                codeContainer.select();
                document.execCommand('copy');
              } else {
                toastr.error('Lỗi', 'Xin vui lòng sao chép thủ công.');
              }
            }}
          >
            Sao chép mã
          </Button>
          <div style={{ float: 'right', marginBottom: 10 }}>
            <Button size="small" primary onClick={this.saveHandler}>Cập nhật thông tin cá nhân</Button>
            <Button size="small" onClick={this.logoutHandler}>Đăng xuất</Button>
          </div>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  signOut, userIdentify
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProfileForm);