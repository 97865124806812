import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Segment,
  Button,
  Dropdown,
  Header,
  Icon,
  Dimmer,
  Loader,
  Label,
  Divider,
  Form,
  Table
} from 'semantic-ui-react';
import Item from './item';
import _ from 'lodash';
import { connect } from 'react-redux';

import TextInput from '../../components/TextInput';

import { db } from '../../libs/firebase';
import { convertTimeStamp } from '../../libs/time';

const cities = require('../../libs/cities.json');


class Units extends Component {
  static propTypes = {
    user: PropTypes.shape({
      admin: PropTypes.bool,
      email: PropTypes.string.isRequired
    }).isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      provinces: [],
      selectedProvince: undefined,
      districts: [],
      selectedDistrict: undefined,
      schools: undefined,
      filter: '',
      hasExpired: false
    }
  }

  componentWillMount() {
    const provinces = [];
    Object.keys(cities).map(key => (
      provinces.push({
        key,
        text: cities[key].name,
        value: key
      })
    ));
    this.setState({ provinces });
  }

  componentWillUnmount() {
    if (this.listenter)
      this.listenter();
  }

  getWard = (province, district, ward) => {
    if (province === undefined || district === undefined || ward === undefined)
      return '';
    if (cities[province] === undefined || cities[province].districts === undefined)
      return '';
    if (cities[province].districts[district] === undefined || cities[province].districts[district].wards === undefined)
      return '';
    if (cities[province].districts[district].wards[ward] === undefined)
      return '';
    return cities[province].districts[district].wards[ward].name;
  }

  schoolHandler = (event, { value }) => {
    this.setState({ selectedSchool: value });
  }

  provinceHandler = (event, { value }) => {
    this.setState({
      selectedProvince: value,
      districts: [],
      selectedDistrict: undefined,
    });
    const districts = [];
    Object.keys(cities[value].districts).map(key => (
      districts.push({
        key,
        text: cities[value].districts[key].name,
        value: key
      })
    ));
    this.setState({ districts });
  }

  districtHandler = (event, { value }) => {
    this.setState({
      selectedDistrict: value
    });
  }

  editSchoolHandler = (school) => {
    const unit = {
      type: "th",
      unitID: school.schoolID,
      name: school.name,
      note: school.note,
      quanlykho: school.quanlykho,
      candoithucdon: school.candoithucdon,
      quanlybantru: school.quanlybantru,
      tinhtienan: school.tinhtienan,
      quanlythuphi: school.quanlythuphi,
      quanlyhocsinh: school.quanlyhocsinh,
      danhmuc: school.danhmuc,
      caidat: school.caidat,
      licenseExpired: (school.licenseExpired !== undefined && !isNaN(school.licenseExpired)) ? school.licenseExpired : undefined,
      province: school.province,
      district: school.district,
      ward: school.ward
    };
    this.setState({
      showUnit: true,
      unit
    });
  }

  // Perform search to find schoolsß
  searchSchoolHander = (searchType, searchValue) => {
    if (searchValue === undefined)
      return;
    this.setState({ isWaiting: true });
    if (this.listenter)
      this.listenter();
    this.listenter = db.collection("units").where(searchType, "==", searchValue).onSnapshot(snapshot => {
      // Initialize results information
      const schools = {
        schools: [],
        th: [],
        options: [], // for searching on combobox
        selectedSchool: undefined,
        searchType,
        searchValue
      };
      // Push all found schools to result
      snapshot.forEach(school => {
        schools.th.push({
          ...school.data(),
          schoolID: school.id
        });
        schools.options.push({
          key: school.id,
          text: school.data().name,
          value: school.id
        });
        schools.schools.push({
          ...school.data()
        });
      });
      this.setState({
        isWaiting: false,
        schools
      });
    }, (error) => {
      if (error)
        this.errorHandler(error);
    });
  }

  newSchoolHander = () => {
    if (this.state.schools.searchType === undefined || this.state.schools.searchValue === undefined)
      return;
    this.setState({
      showUnit: true,
      unit: {
        name: "Trường",
        province: (this.state.schools.searchType === "province") ? this.state.schools.searchValue : this.state.schools.searchValue.slice(0, 2),
        district: (this.state.schools.searchType === "district") ? this.state.schools.searchValue : undefined
      }
    })
  }

  errorHandler = (message) => {
    this.setState({ isWaiting: false });
    this.notificationSystem.addNotification({
      title: "Lỗi",
      message,
      level: "error",
      position: "tr",
      autoDismiss: 4
    });
  }

  successHandler = (message) => {
    this.setState({ isWaiting: false });
    this.notificationSystem.addNotification({
      title: "Hoàn tất",
      message,
      level: "success",
      position: "tr",
      autoDismiss: 4
    });
  }

  renderUnitEditor = () => {
    const { user } = this.props;
    const { showUnit, unit } = this.state;
    if (showUnit)
      return (
        <Item
          admin={_.get(user, 'admin', false)}
          staff={user.email}
          unit={unit}
          closeHandler={() => this.setState({ showUnit: false, unit: undefined })}
        />
      );
    return null;
  }

  renderSchools = () => {
    const headerStyles = {
      color: '#8798AD',
      textAlign: 'left'
    };
    const { schools, filter } = this.state;
    const filtered = _.filter(schools.th || [], school => {
      if (!filter)
        return true;
      return _.indexOf(_.toLower(school.name || ''), _.toLower(filter)) !== -1;
    });

    return (
      <Segment>
        <Header as="h4">
          <Button basic color="blue" floated="right" icon="add" content="Tạo mới" onClick={this.newSchoolHander} />
          {(this.state.schools.searchType === "province") ? "Mã tỉnh" : "Mã quận"}: {this.state.schools.searchValue}
          <Header.Subheader>Số trường đã tạo: {this.state.schools.schools.length}</Header.Subheader>
        </Header>
        <Divider />
        <Table
          style={{ border: 'none' }}
          basic
          striped
          unstackable
        >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell collapsing style={headerStyles}>Mã trường</Table.HeaderCell>
              <Table.HeaderCell style={headerStyles}>Tên trường</Table.HeaderCell>
              <Table.HeaderCell collapsing style={headerStyles}>Phường</Table.HeaderCell>
              <Table.HeaderCell collapsing style={headerStyles}>
                Hạn sử dụng
                <Label circular color="red" style={{ marginLeft: 10 }}>
                  {_.filter(filtered, school => !school.licenseExpired || isNaN(school.licenseExpired)).length}
                </Label>
              </Table.HeaderCell>
              <Table.HeaderCell collapsing style={headerStyles} />
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell colSpan="5">
                <TextInput
                  value={filter}
                  placeholder="Lọc theo tên trường"
                  onChange={value => this.setState({ filter: value })}
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {_.map(_.orderBy(filtered, school => school.name), (school, index) => this.renderResultRow(school, index))}
          </Table.Body>
        </Table>
      </Segment>
    );
  }

  renderResultRow = (school, index) => {
    const {
      schoolID,
      name,
      province,
      district,
      ward
    } = school;
    return (
      <Table.Row key={index}>
        <Table.Cell collapsing>{schoolID}</Table.Cell>
        <Table.Cell>{name}</Table.Cell>
        <Table.Cell collapsing>{_.get(cities, `${province}.districts.${district}.wards.${ward}.name`, '')}</Table.Cell>
        <Table.Cell collapsing>{(school.licenseExpired !== undefined && !isNaN(school.licenseExpired)) ? convertTimeStamp(school.licenseExpired, true) : "Chưa có hạn sử dụng"}</Table.Cell>
        <Table.Cell collapsing>
          <Icon
            style={{ cursor: 'pointer' }}
            name="edit"
            color="blue"
            onClick={() => this.editSchoolHandler(school)}
          />
        </Table.Cell>
      </Table.Row>
    );
  }

  render() {
    const {
      isWaiting,
      unit,
      provinces,
      districts,
      selectedDistrict,
      selectedProvince
    } = this.state;
    const { user } = this.props;
    return (
      <div>
        {isWaiting ?
          <Dimmer active inverted>
            <Loader inverted>Đang kết nối...</Loader>
          </Dimmer>
          : null
        }
        {unit ? this.renderUnitEditor() : null}
        <Segment>
          <Header as="h4">Thông tin tìm kiếm</Header>
          <Divider />
          <Form>
            <Form.Group inline>
              <Form.Field>
                <label>Tỉnh/Thành</label>
                <Dropdown
                  search
                  selection
                  multiple={false}
                  onChange={this.provinceHandler}
                  options={provinces} value={selectedProvince}
                  placeholder="Nhập tên Tỉnh/Thành"
                />
                {_.get(user, 'admin') ? (
                  <Button.Group>
                    <Button icon="undo" color="teal" onClick={() => this.setState({ selectedProvince: undefined, districts: [], selectedDistrict: undefined })} />
                    <Button icon="search" color="blue" onClick={() => this.searchSchoolHander("province", this.state.selectedProvince)} />
                  </Button.Group>
                ) : null
                }
              </Form.Field>
              <Form.Field>
                <label>Quận/Huyện</label>
                <Dropdown
                  search
                  selection
                  multiple={false}
                  onChange={this.districtHandler}
                  options={districts}
                  value={selectedDistrict}
                  placeholder="Nhập tên Quận/Huyện"
                />
              </Form.Field>
              <Button.Group>
                <Button icon="undo" color="teal" onClick={() => this.setState({ selectedDistrict: undefined })} />
                <Button icon="search" color="blue" onClick={() => this.searchSchoolHander("district", selectedDistrict)} />
              </Button.Group>
            </Form.Group>
          </Form>
        </Segment>
        {_.get(this.state.schools, 'searchType') ? this.renderSchools() : null}
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => ({
  user
});

export default connect(mapStateToProps)(Units);
