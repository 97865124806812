import { USER_FETCH, USER_IDENTIFY, SIGN_OUT, USER_UNIT, USER_STAFF, USER_FEATURES, USER_CODE } from './constants';


/**
 * Fetch general information of a user
 *
 * @module Redux.Actions.Users
 * @ngdoc method
 * @name $userFetchInformation
 * @param  {String} [displayName]
 * @param  {String} [email]
 * @param  {String} [phoneNumber]
 * @param  {String} [uid]
 */
export function userFetchInformation(displayName, email, phoneNumber, uid) {
  const name = (displayName === null) ? '' : displayName;
  const phone = (phoneNumber === null) ? '' : phoneNumber;
  return {
    type: USER_FETCH,
    displayName: name,
    email,
    phoneNumber: phone,
    uid
  };
}

/**
 * Fetch detail information of a user
 *
 * @module Redux.Actions.Users
 * @ngdoc method
 * @name $userIdentify
 * @param  {String} [displayName]
 * @param  {String} [phoneNumber]
 * @param  {String} [gender] 'M' or 'F', could be undefined
 */
export function userIdentify(displayName, phoneNumber, gender) {
  const name = (displayName === undefined) ? '' : displayName;
  const phone = (phoneNumber === undefined) ? '' : phoneNumber;
  return {
    type: USER_IDENTIFY,
    displayName: name,
    phoneNumber: phone,
    gender
  };
}

/**
 * Add units information detail which user belongs to
 *
 * @module Redux.Actions.Users
 * @ngdoc method
 * @name $userAddUnit
 * @param  {Object} [unit]
 */

export function userUpdateUnit(unit) {
  return {
    type: USER_UNIT,
    unit
  };
}

/**
 * Update staff flag
 *
 * @module Redux.Actions.Users
 * @ngdoc method
 * @name $userStaff
 */

export function userStaff(admin) {
  return {
    type: USER_STAFF,
    admin
  };
}

/**
 * Update features
 *
 * @module Redux.Actions.Users
 * @ngdoc method
 * @name $userFeatures
 */

export function userFeatures(features) {
  return {
    type: USER_FEATURES,
    features
  };
}

/**
 * User has signed out
 *
 * @module Redux.Actions.Users
 * @ngdoc method
 * @name $signOut
 */

export function signOut() {
  return {
    type: SIGN_OUT
  };
}

export function userCode(code) {
  return {
    type: USER_CODE,
    code,
  };
}