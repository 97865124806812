import _ from 'lodash';

import {
  USER_FETCH,
  USER_IDENTIFY,
  USER_UNIT,
  SIGN_OUT,
  USER_STAFF,
  USER_FEATURES,
  USER_CODE,
} from '../actions/constants';
import { toString } from '../../libs/time';


const initialState = {
  displayName: '',
  email: '',
  emailVerified: false,
  phoneNumber: '',
  gender: undefined,
  uid: '',
  unit: undefined,
  features: ''
};

export default function user(state = initialState, action) {
  switch (action.type) {
    case USER_FETCH:
      console.log(`email:${action.email}`); // eslint-disable-line no-console
      return {
        ...state,
        displayName: action.displayName,
        email: action.email,
        emailVerified: action.emailVerified,
        phoneNumber: action.phoneNumber,
        uid: action.uid
      };
    case USER_IDENTIFY:
      return {
        ...state,
        displayName: action.displayName,
        phoneNumber: action.phoneNumber,
        gender: action.gender
      };
    case USER_UNIT:
      console.log(`unit-name:${action.unit.name}`); // eslint-disable-line no-console
      console.log(`unit-ward:${action.unit.ward}`); // eslint-disable-line no-console
      console.log(`unit-quanlykho:${action.unit.quanlykho}`); // eslint-disable-line no-console
      console.log(`unit-candoithucdon:${action.unit.candoithucdon}`); // eslint-disable-line no-console
      console.log(`unit-quanlybantru:${action.unit.quanlybantru}`); // eslint-disable-line no-console
      console.log(`unit-tinhtienan:${action.unit.tinhtienan}`); // eslint-disable-line no-console
      console.log(`unit-quanlythuphi:${action.unit.quanlythuphi}`); // eslint-disable-line no-console
      console.log(`unit-quanlyhocsinh:${action.unit.quanlyhocsinh}`); // eslint-disable-line no-console
      console.log(`unit-danhmuc:${action.unit.danhmuc}`); // eslint-disable-line no-console
      console.log(`unit-caidat:${action.unit.caidat}`); // eslint-disable-line no-console
      if (action.unit.licenseExpired !== undefined)
        console.log(`unit-licenseexpired:${toString(action.unit.licenseExpired)}`); // eslint-disable-line no-console
      return {
        ...state,
        unit: action.unit
      };
    case USER_STAFF:
      console.log('staff'); // eslint-disable-line no-console
      return {
        ...state,
        staff: true,
        admin: _.get(action, 'admin', false)
      };
    case USER_FEATURES:
      console.log(`features:${action.features}`); // eslint-disable-line no-console
      return {
        ...state,
        features: action.features
      };
    case USER_CODE:
      console.log(`code:${action.code}`); // eslint-disable-line no-console
      return {
        ...state,
        code: action.code,
      };
    case SIGN_OUT:
      return initialState;
    default:
      return state;
  }
}